import React, {useCallback, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, push, set } from "firebase/database";
import {useImmer} from "use-immer";
import produce from "immer";

const firebaseConfig = {
  apiKey: "AIzaSyBsR8w4UaCdaLNTyoIfVVhKRkn5tSNse10",
  authDomain: "brgchesstournament.firebaseapp.com",
  projectId: "brgchesstournament",
  storageBucket: "brgchesstournament.appspot.com",
  messagingSenderId: "10836155943",
  appId: "1:10836155943:web:a051cdc1c5ac2d20397802",
  databaseURL: "https://brgchesstournament-default-rtdb.europe-west1.firebasedatabase.app"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const stateRef = ref(database, "/state");

const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];

const BOARD_COUNT = 4;

const analysis = analyzePerformance();

type State = {
  players: string[],
  rounds: { games: {a: string, b: string, aCol: 'White' | 'Black', outcome: 'aWon' | 'bWon' | 'draw' | null}[], leavingPlayers: string[] }[]
}

function App() {
  let [state, _setState] = useState({ players: ["Loading..."], rounds: [] } as State);
  let [newPlayer, setNewPlayer] = useState("");
  let [callbackRegistered, setCallbackRegistered] = useState(false);
  const removed = state.rounds.flatMap((x) => (x.leavingPlayers));
  const isAdmin = window.location.search.includes("admin123");
  const points = getPoints(state);
  const readyForNextRound = allFinished(state);
  const debugMode = false;

  useEffect(() => {
    if(!callbackRegistered) {
      setCallbackRegistered(true);
      onValue(stateRef, (val) => {
        _setState(JSON.parse(val.val()));
      });
    }
  }, [callbackRegistered])

  // pt-5 border-[#FFFF00] border-[15px]

  return (
    <div className={"grid place-items-center w-[100%]"}>
      {isAdmin && debugMode ? <>{analysis}</> : <></>}
      <h1 className={"text-center text-7xl font-black underline underline underline-offset-8 mt-5"}>Spieler</h1>
      <div className={"w-[80%] mt-5"}>
        {state.players.slice().sort(
          (a, b) =>
            ((points.get(b)?.points ?? 0) - (points.get(a)?.points ?? 0))
            || ((points.get(b)?.tiebreakerPoints ?? 0) - (points.get(a)?.tiebreakerPoints ?? 0))
        ).map((player, idx) => {
          let playerStr = <><span className={"text-grey"}>{points.get(player)?.rank ?? NaN}.</span> {player}</>
          if(removed.includes(player)) {
            playerStr = <s>{playerStr}</s>
          }
          if(isAdmin) {
            if(state.rounds.length > 0 && state.rounds[state.rounds.length - 1].leavingPlayers.includes(player)) {
              playerStr = <>{playerStr}<button className={"pl-2"} onClick={() => {
                let newState = produce(state, (state: any) => {
                  state.rounds[state.rounds.length - 1].leavingPlayers.splice(state.rounds[state.rounds.length - 1].leavingPlayers.indexOf(player), 1);
                });
                setNewState(newState);
              }}>[+]</button></>;
            }
            if(!removed.includes(player)) {
              playerStr = <>{playerStr}<button className={"pl-2"} onClick={() => {
                if(state.rounds.length === 0) {
                  let newState = produce(state, (state: any) => {
                    state.players.splice(idx, 1);
                  });
                  setNewState(newState);
                } else {
                  let newState = produce(state, (state: any) => {
                    state.rounds[state.rounds.length - 1].leavingPlayers.push(player);
                  });
                  setNewState(newState);
                }
              }}>[X]</button></>;
            }
          }
          return <div className={"flex flex-row text-xl"}>
            <div>{playerStr}</div>
            <div className={"relative bottom-[8px] flex-grow border-b-[2.5px] border-dotted border-black mr-1 ml-1"}></div>
            <div className={"float-right"}>{Math.round((points.get(player)?.points ?? 0) * 100) / 100}</div>
          </div>;
        })}
      </div>
      {isAdmin ? <>
        <input className={"bg-slate-200 px-3 py-1 rounded text-center"} value={newPlayer} onChange={(x) => setNewPlayer(x.target.value)}/>
        <button onClick={
          () => {
            let newState = produce(state, (state: any) => {
              state.players.push(newPlayer);
            });
            setNewState(newState);
          }
        }>Add player</button>
      </> : <></>}
      <h1 className={"text-center text-7xl font-black underline underline underline-offset-8 mt-5"}>Runden</h1>
      {state.rounds.map((round, roundIdx) => {
        let pointsUpToRound = getPoints(state, roundIdx);

        return <>
          <h2 className={"text-center text-5xl mt-4"}>Runde {romanNumerals[roundIdx]}{
            isAdmin && roundIdx === state.rounds.length - 1 ? (<> <button onClick={
              () => {
                let newState = produce(state, (state: any) => {
                  state.rounds.pop();
                });
                setNewState(newState);
              }
            }>[X]</button></>) : (<></>)
          }</h2>
          <div className={"w-[80%]"}>
            <div className={"flex flex-row w-full h-10 rounded my-3"}>
              <div className={`w-[50%] h-full flex flex-row items-center pl-2`}>
                <p className={`font-bold text-xl`}>Weiß</p>
              </div>
              <div className={`w-[50%] h-full flex flex-row items-center justify-end pr-2`}>
                <p className={`font-bold text-xl`}>Schwarz</p>
              </div>
            </div>

            {round.games.map((game, gameIdx) => {
              let a: string;
              let b: string;
              let aPoints;
              let bPoints;
              let aStatus;
              let bStatus;
              let aHalved;
              let bHalved;
              let swapped = game.aCol === "Black";

              if(swapped) {
                b = game.a;
                a = game.b;
                bStatus = game.outcome === 'aWon' || game.outcome === 'draw';
                aStatus = game.outcome === 'bWon' || game.outcome === 'draw';
              }  else {
                a = game.a;
                b = game.b;
                aStatus = game.outcome === 'aWon' || game.outcome === 'draw';
                bStatus = game.outcome === 'bWon' || game.outcome === 'draw';
              }

              aPoints = pointsUpToRound.get(a)?.points ?? 0;
              bPoints = pointsUpToRound.get(b)?.points ?? 0;

              const fracs = ["inf", "1", "½", "⅓", "¼"];
              let aFrac = round.games.filter((otherGame) => otherGame.a === a || otherGame.b === a).length;
              let bFrac = round.games.filter((otherGame) => otherGame.a === b || otherGame.b === b).length;

              return <div className={"flex flex-row w-full h-10 rounded border-4 border-primary my-3"}>
                <div className={`w-[50%] h-full flex flex-row items-center pl-2 ${aStatus ? "bg-primary" : ""}`}
                     onClick={() => {if(isAdmin) {toggleGameUser(state, roundIdx, gameIdx, swapped ? 'b' : 'a')}}}>
                  <p className={`font-bold ${aStatus ? "text-white" : ""} text-xl`}>{a}{aFrac !== 1 ? ` (${fracs[aFrac]})` : ""}</p>
                  <div className={"flex-grow"}></div>
                  <p className={`font-bold ${aStatus ? "text-white" : ""} text-sm mr-2`}>{aPoints}P</p>
                </div>
                <div className={`w-[50%] h-full flex flex-row items-center justify-end pr-2 ${bStatus ? "bg-primary" : ""}`}
                     onClick={() => {if(isAdmin) {toggleGameUser(state, roundIdx, gameIdx, swapped ? 'a' : 'b')}}}>
                  <p className={`font-bold ${bStatus ? "text-white" : ""} text-sm ml-2`}>{bPoints}P</p>
                  <div className={"flex-grow"}></div>
                  <p className={`font-bold ${bStatus ? "text-white" : ""} text-xl`}>{bFrac !== 1 ? `(${fracs[bFrac]}) ` : ""}{b}</p>
                </div>
              </div>;
            })}
          </div>
        </>
      })}
      {(isAdmin && readyForNextRound) ? <>
        <button onClick={() => {
          if(readyForNextRound) {
            addNextRound(state, points, removed);
          }
        }}>Nächste Runde generieren</button>
      </> : <></>}
      {(isAdmin) ? <>
        <button onClick={() => {
          if(window.confirm("Runden zurücksetzen?")) {
            let newState = produce(state, (state: any) => {
              state.rounds = [];
              state.players = shuffle(state.players);
            });
            setNewState(newState);
          }
        }}>Runden zurücksetzen</button>
      </> : <></>}
    </div>
  );
}

function analyzePerformance(): JSX.Element {
  let PLAYER_COUNT = 19;
  let players = [...Array(PLAYER_COUNT).keys()].map((x) => x.toString());
  let ROUND_COUNT = 10;
  let SAMPLE_COUNT = 100;
  let analysis = [<p>Performance analysis ({PLAYER_COUNT} players, {SAMPLE_COUNT} samples):</p>];
  let roundWeights = [...Array(ROUND_COUNT).keys()].map((x) => 0);
  let roundAverages = [...Array(ROUND_COUNT).keys()].map((x) => 0);
  let duplicateRoundAverages = [...Array(ROUND_COUNT).keys()].map((x) => 0);
  let worstMatchupRoundAverages = [...Array(ROUND_COUNT).keys()].map((x) => 0);

  for(let i = 0; i < SAMPLE_COUNT; i++) {
    let worstMatchup = 0;

    let state = {
      players: players,
      rounds: []
    } as State;

    for(let roundIndex = 0; roundIndex < ROUND_COUNT; roundIndex++) {
      let points = getPoints(state);
      addNextRound(state, points, [], (newState: State) => { state = newState });
      state.rounds[roundIndex].games.forEach((game, gameIndex) => {
        let pointDifference = Math.abs((points.get(game.a)?.points ?? 0) - (points.get(game.b)?.points ?? 0));
        if(pointDifference > worstMatchup) {
          worstMatchup = pointDifference;
        }

        let aRank = parseInt(game.a);
        let bRank = parseInt(game.b);
        if(Math.abs(aRank - bRank) <= 1 && Math.random() < 0.5) {
          state = produce(state, (state) => {
            state.rounds[roundIndex].games[gameIndex].outcome = 'draw';
          });
        }
        else if(aRank < bRank) {
          state = produce(state, (state) => {
            state.rounds[roundIndex].games[gameIndex].outcome = 'aWon';
          });
        }
        else if(bRank < aRank) {
          state = produce(state, (state) => {
            state.rounds[roundIndex].games[gameIndex].outcome = 'bWon';
          });
        }
      });
      roundAverages[roundIndex] = (roundAverages[roundIndex] * roundWeights[roundIndex] + calculateTotalDeviation(points)) / (roundWeights[roundIndex] + 1);
      roundWeights[roundIndex] += 1;
      let allGames = state.rounds.flatMap((round) => round.games);
      let duplicateGames = allGames.filter((a) => {
        let identicalMatchups = allGames.filter((b) => {
          return b !== a && ((a.a === b.a && a.b === b.b) || (a.b === b.a && a.a === b.b));
        });
        return identicalMatchups.length > 0;
      });
      duplicateRoundAverages[roundIndex] = (duplicateRoundAverages[roundIndex] * roundWeights[roundIndex] + duplicateGames.length) / (roundWeights[roundIndex] + 1);
      worstMatchupRoundAverages[roundIndex] = (worstMatchupRoundAverages[roundIndex] * roundWeights[roundIndex] + worstMatchup) / (roundWeights[roundIndex] + 1);
    }
  }

  for(let i = 0; i < ROUND_COUNT; i++) {
    analysis.push(<p>{`[${i + 1}] loss: ${roundAverages[i].toFixed(1)}, worst diff: ${worstMatchupRoundAverages[i].toFixed(1)}, duplicates: ${duplicateRoundAverages[i].toFixed(1)}`}</p>);
  }

  return <>{analysis}</>;
}

function calculateTotalDeviation(points: Map<string, { points: number, tiebreakerPoints: number, rank: number }>) {
  return Math.sqrt(Array.from(points.entries()).map(([player, {points, tiebreakerPoints, rank}]) => {
    let playerTargetRank = parseInt(player) + 1;
    let deviation = Math.abs(playerTargetRank - rank);
    return deviation * deviation;
  }).reduce((a, b) => a + b, 0));
}

function toggleGameUser(state: State, roundIdx: number, gameIdx: number, player: 'a' | 'b') {
  if(roundIdx !== state.rounds.length - 1) {
    return;
  }

  let newState = produce(state, (state: any) => {
    let current = state.rounds[roundIdx].games[gameIdx].outcome;
    if(current === 'aWon' && player === 'a') {
      state.rounds[roundIdx].games[gameIdx].outcome = null;
    }
    if(current === 'aWon' && player === 'b') {
      state.rounds[roundIdx].games[gameIdx].outcome = 'draw';
    }
    if(current === 'bWon' && player === 'a') {
      state.rounds[roundIdx].games[gameIdx].outcome = 'draw';
    }
    if(current === 'bWon' && player === 'b') {
      state.rounds[roundIdx].games[gameIdx].outcome = null;
    }
    if(current === 'draw' && player === 'a') {
      state.rounds[roundIdx].games[gameIdx].outcome = 'bWon';
    }
    if(current === 'draw' && player === 'b') {
      state.rounds[roundIdx].games[gameIdx].outcome = 'aWon';
    }
    if(current === null && player === 'a') {
      state.rounds[roundIdx].games[gameIdx].outcome = 'aWon';
    }
    if(current === null && player === 'b') {
      state.rounds[roundIdx].games[gameIdx].outcome = 'bWon';
    }
  });
  setNewState(newState)
}

function getPoints(state: State, upToRound?: number): Map<string, { points: number, tiebreakerPoints: number, rank: number }> {
  let finishedRounds = state.rounds.slice(0, upToRound ?? state.rounds.length).filter((x) => x.games.every(game => game.outcome !== null));
  let points = new Map();
  finishedRounds.forEach((round, roundIdx) => {
    round.games.forEach((game, gameIdx) => {
      let aGameCount = round.games.filter((x) => x.a === game.a || x.b === game.a).length;
      let bGameCount = round.games.filter((x) => x.a === game.b || x.b === game.b).length;
      // let mult = Math.pow(0.8, roundIdx);
      let mult = 1;
      let MAX_POINT_DIFF = 10000;
      if(game.outcome === 'aWon') {
        if((points.get(game.a) ?? 0) - MAX_POINT_DIFF < (points.get(game.b) ?? 0)) {
          points.set(game.a, (points.get(game.a) ?? 0) + (mult / aGameCount));
        } else {
          points.set(game.a, (points.get(game.a) ?? 0) + ((mult * 0.5) / aGameCount));
        }
      } else if(game.outcome === 'bWon') {
        if((points.get(game.b) ?? 0) - MAX_POINT_DIFF < (points.get(game.a) ?? 0)) {
          points.set(game.b, (points.get(game.b) ?? 0) + (mult / bGameCount));
        } else {
          points.set(game.b, (points.get(game.b) ?? 0) + ((mult * 0.5) / bGameCount));
        }
      } else if(game.outcome === 'draw') {
        if((points.get(game.a) ?? 0) - MAX_POINT_DIFF < (points.get(game.b) ?? 0)) {
          points.set(game.a, (points.get(game.a) ?? 0) + ((mult * 0.5) / aGameCount));
        }
        if((points.get(game.b) ?? 0) - MAX_POINT_DIFF < (points.get(game.a) ?? 0)) {
          points.set(game.b, (points.get(game.b) ?? 0) + ((mult * 0.5) / bGameCount));
        }
      }
    })
  });
  let tiebreakerPoints = new Map();
  finishedRounds.forEach((round, roundIdx) => {
    round.games.forEach((game, gameIdx) => {
      let aGameCount = round.games.filter((x) => x.a === game.a || x.b === game.a).length;
      let bGameCount = round.games.filter((x) => x.a === game.b || x.b === game.b).length;
      if(game.outcome === 'aWon') {
        tiebreakerPoints.set(game.a, (tiebreakerPoints.get(game.a) ?? 0) + ((points.get(game.b) ?? 0) / aGameCount));
      } else if(game.outcome === 'bWon') {
        tiebreakerPoints.set(game.b, (tiebreakerPoints.get(game.b) ?? 0) + ((points.get(game.a) ?? 0) / bGameCount));
      } else if(game.outcome === 'draw') {
        tiebreakerPoints.set(game.a, (tiebreakerPoints.get(game.a) ?? 0) + (((points.get(game.a) ?? 0) / 2) / aGameCount));
        tiebreakerPoints.set(game.b, (tiebreakerPoints.get(game.b) ?? 0) + (((points.get(game.b) ?? 0) / 2) / bGameCount));
      }
    })
  });
  let rank = new Map();
  let playerPoints = state.players.map((player) => [points.get(player) ?? 0, tiebreakerPoints.get(player) ?? 0]);
  state.players.forEach((player) => {
    let myPoints = points.get(player) ?? 0;
    let myTiebreakerPoints = tiebreakerPoints.get(player) ?? 0;
    let better = playerPoints.filter(([tgtPoints, tgtTiebreakerPoints]) => tgtPoints > myPoints || (tgtPoints === myPoints && tgtTiebreakerPoints > myTiebreakerPoints));
    rank.set(player, better.length);
  })

  return new Map(Array.from(rank.entries()).map(([key, valRank]) => [key, { points: points.get(key), tiebreakerPoints: tiebreakerPoints.get(key), rank: valRank + 1 }]));
}

function addNextRound(state: State, points: Map<string, { points: number, tiebreakerPoints: number, rank: number }>, removed: string[], setState: any = setNewState) {
  let players = state.players.filter((x) => !removed.includes(x));
  let allPreviousGames = state.rounds.flatMap((x) => x.games);
  let possibleMatches = players.flatMap((a, aIdx) => {
    let candidates = players.slice();
    candidates.splice(aIdx, 1);
    return candidates.map((b) => [a, b]);
  });

  let matchScores = possibleMatches.flatMap(([a, b]) => {
    if(state.players.indexOf(b) < state.players.indexOf(a)) {
      return [];
    }
    let pointDifference = Math.abs((points.get(a)?.points ?? 0) - (points.get(b)?.points ?? 0));
    let totalRank = (points.get(a)?.rank ?? 0) + (points.get(b)?.rank ?? 0);
    let totalTiebreaker = (points.get(a)?.tiebreakerPoints ?? 0) + (points.get(b)?.tiebreakerPoints ?? 0);
    let tiebreakerDifference = Math.abs((points.get(a)?.tiebreakerPoints ?? 0) - (points.get(b)?.tiebreakerPoints ?? 0));
    let matchOccurrences = allPreviousGames
      .filter((match) => (match.a === a && match.b === b) || (match.a === a && match.b === b))
      .length;
    return [[a, b, totalRank + (matchOccurrences > 0 && state.rounds.length < 5 ? 1000 : 0), 0] as [string, string, number, number]];
  }) as [string, string, number, number][];
  matchScores = shuffle(matchScores);
  matchScores.sort((
    [aa, ab, aLoss, aTiebreakerLoss], [ba, bb, bLoss, bTiebreakerLoss]) => (aLoss - bLoss) || (aTiebreakerLoss - bTiebreakerLoss));

  let chosen: [string, string, number, number][] = [];
  let allowDouble = players.length % 2 === 1;
  let gamesNeeded = Math.ceil(players.length / 2);

  while(gamesNeeded > 0) {
    let game = matchScores.splice(0, 1)[0];
    if(allowDouble) {
      allowDouble = false;
      if(Math.random() < 0.5) {
        matchScores = matchScores.filter((x) => x[0] !== game[0] && x[1] !== game[0]);
      } else {
        matchScores = matchScores.filter((x) => x[0] !== game[1] && x[1] !== game[1]);
      }
    } else {
      matchScores = matchScores.filter((x) => x[0] !== game[0] && x[0] !== game[1] && x[1] !== game[0] && x[1] !== game[1]);
    }
    chosen.push(game);
    gamesNeeded--;
  }

  let newState = produce(state, (state: any) => {
    state.rounds.push({
      leavingPlayers: [],
      games: shuffle(chosen.map((game) => {
        let aColor = null;
        if((points.get(game[0])?.points ?? 0) > (points.get(game[1])?.points ?? 0)) {
          aColor = 'Black';
        } else if((points.get(game[0])?.points ?? 0) < (points.get(game[1])?.points ?? 0)) {
          aColor = 'White';
        } else if(Math.random() < 0.5) {
          aColor = 'Black';
        } else {
          aColor = 'White';
        }
        return {
          a: game[0],
          b: game[1],
          aCol: aColor,
          outcome: null
        };
      }))
    });
  });

  setState(newState)
}

function setNewState(newState: State) {
  let str = JSON.stringify(newState);
  set(stateRef, str)
}

function allFinished(state: State): boolean {
  return state.rounds.every((x) => x.games.every(game => game.outcome !== null));
}

function shuffle(array: any) {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default App;
